import React from "react";
import "./Floater.scss";


const Floater = (props) => {
  return (
    <>
      <div className="page-floater-basl mobile-none">
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yl83ai.png" loading="lazy" />
          <div className="floater-title">Program Duration </div>
          <div className="floater-sub">9 Months</div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylobas.png" loading="lazy" />
          <div className="floater-title">LIVE Projects</div>
          <div className="floater-sub">with Nestlé & Turbohire</div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylc9fe.png" loading="lazy" />
          <div className="floater-title"> Highest CTC</div>
          <div className="floater-sub">
            of ₹17.2 LPA
          </div>
        </div> 
        
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yktf41.png" loading="lazy" />
          <div className="floater-title"> Average CTC</div>
          <div className="floater-sub">
            of ₹10.5 LPA
          </div>
        </div>

        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylrq4c.png" loading="lazy" />
          <div className="floater-title">Mode</div>
          <div className="floater-sub">
            Online,<br className="pc-hide" /> Full-Time
          </div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yl2dvw.png" loading="lazy" />
          <div className="floater-title"> Batch of 2025</div>
          <div className="floater-sub">
          March 25th
          </div>
        </div>
       
      </div>

      <div className="page-floater-basl pc-none">
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yl83ai.png" loading="lazy" />
          <div className="floater-title">Program Duration </div>
          <div className="floater-sub">9 Months</div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylobas.png" loading="lazy" />
          <div className="floater-title">LIVE Projects</div>
          <div className="floater-sub">with Nestlé & Turbohire</div>
        </div>
        <hr class="yellow-line" />
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylc9fe.png" loading="lazy" />
          <div className="floater-title"> Highest CTC</div>
          <div className="floater-sub">
            of ₹17.2 LPA
          </div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yktf41.png" loading="lazy" />
          <div className="floater-title"> Average CTC</div>
          <div className="floater-sub">
            of ₹10.5 LPA
          </div>
        </div>
        <hr class="yellow-line" />
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ylrq4c.png" loading="lazy" />
          <div className="floater-title">Mode</div>
          <div className="floater-sub">
            Online,<br className="pc-hide"/> Full-Time
          </div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yl2dvw.png" loading="lazy" />
          <div className="floater-title"> Batch of 2025</div>
          <div className="floater-sub">
          March 25th
          </div>
        </div>
      </div>

      {/* <div className="page-floater-mob pc-hide">
        <StaticImage
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m22qxqka.webp"
          alt="batch starts 23 march"
        />
       
      </div> */}
    </>
  );
};

export default Floater;
