import React from "react";
import Slider from "react-slick";
import Card5 from "../../../TestimonialCard/Card-blogs";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

function CourseTracks({ FtCta, ETCta, PTCta, CSMCta, PGPCta, slug }) {
  const location = useLocation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: true,
        },
      },
    ],
  };

  const trainersImages = [
    {
      title: "Business Leadership",
      name: "PGP in Sales and",
      subTitle: "",
      mobSubTitle: "PGP in Sales and Business Leadership",
      description: "9 months | Full-Time",
      link: `https://www.kraftshala.com/sales-course/?utm_source=carousel&utm_medium=blog&utm_content=${slug}`,
    },
    {
      title: "Marketing Launchpad",
      name: "Digital",
      description: "19 Weeks | Full-Time",
      link: `https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/?utm_source=carousel&utm_medium=blog&utm_content=${slug}`,
    },
    {
      title: "Marketing Launchpad ",
      name: "Digital",
      description: "8 Months | Part-Time",
      link: `https://www.kraftshala.com/marketing-launchpad/part-time-digital-marketing-course/?utm_source=carousel&utm_medium=blog&utm_content=${slug}`,
    },
    
    {
      title: "Launchpad - Emerging Talent",
      name: "Digital  Marketing",
      subTitle: "(Hindi & English)",
      description: "19 Weeks | Full-Time",
      link: `https://www.kraftshala.com/marketing-launchpad/digital-marketing-course-in-hindi/?utm_source=carousel&utm_medium=blog&utm_content=${slug}`,
    },
    {
      title: "Media Launchpad",
      name: "Content & Social",
      description: "16 Weeks | Part-Time",
      link: `https://www.kraftshala.com/content-writing-course/?utm_source=carousel&utm_medium=blog&utm_content=${slug}`,
    },
  ];

  return (
    <div className="csm-course-main-trainers blogs-trainers" id="blog-tracks">
      <hr />
      <div className="will-be-training">
        <div className="trainers-container">
          <h2 className="training-section-title-blog">
            {" "}
            Check out our courses{" "}
          </h2>

          <span className="about-us-story-underline-img">
            <StaticImage
              src="https://kfwebassets.s3.ap-south-1.amazonaws.com/common/Group-11278.png"
              alt="title underlines"
              placeholder="none"
            />
          </span>

          <div className="csm-trainers-slider fb-track blogs-track">
            <Slider {...settings}>
              {trainersImages.map((info, key) => (
                <Card5
                  key={key}
                  name={info.name}
                  title={info.title ? info.title : ""}
                  description={info.description}
                  subTitle={info.subTitle ? info.subTitle : ""}
                  link={info.link + location?.search}
                  mobSubTitle={info.mobSubTitle ? info.mobSubTitle : ""}
                  page={"csm"}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseTracks;
