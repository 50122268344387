import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Whatsapp from "./Whatsapp/whatsapp";
import Input from "./Input/input";
import { CONVEX_API_URL } from "../../../../config/config";
import './styles.scss';

let formData = {
  name: "",
  email: "",
  phone: "",
  opt_in: "",
  // reference: ""
};

const NewKsLiveForm = (props) => {
  const { search, eventDetails } = props;
  let queryParams = new URLSearchParams(search);

  let utmMedium = queryParams.get("utm_medium") || "No Utm Medium";
  let utmSource = queryParams.get("utm_source") || "No Utm Source";
  let utmContent = queryParams.get("utm_content") || "No Utm Content";
  let utmCampaign = queryParams.get("utm_campaign") || "No Utm Campaign";
  let utmTerm = queryParams.get("utm_term") || "No Utm Term";
  let fromHomepage = !!queryParams.get("home_page") || false;

  let gaID = "No GA.ID";
  const [btnClicked, setBtnClicked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);

  const [formFields, setFormFields] = useState(formData);

  const [checkType, setType] = useState(false);
  const [scrollValidation, setScrollValidation] = useState(false);

  const [allFilled, setAllFilled] = useState(false);

  const [emailNotExists, setEmailNotExists] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  const [gclid, setGclid] = useState(queryParams.get("gclid") || "");
  const [gclsrc, setGclidSrc] = useState(queryParams.get("gclsrc") || "");
  const [wbraid, setWbraid] = useState(queryParams.get("wbraid") || "");
  const [gbraid, setGbraid] = useState(queryParams.get("gbraid") || "");
  const [thankyou,setThankyou] = useState(false);

  useEffect(() => {
    syncGclId();
  }, []);

  useEffect(() => {
    const scrollToErr = () => {
      const errElement = document.querySelector(".validation-err");

      if (errElement) {
        errElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    };

    scrollToErr();
  }, [scrollValidation]);

  const getValuesFn = (data) => {
    setType(!checkType);
    formData[data.id] = data.value;

    if (data.id === "email") {
      formData[data.id] = data.value.toLowerCase();
    }

    checkAll();

    setFormFields(formData);
  };

  const checkAll = () => {
    const isComplete = [];

    Object.keys(formFields).forEach(function (key) {
      if (formFields[key] === "") {
        isComplete.push(false);
      }
    });

    if (isComplete.includes(false)) {
      setAllFilled(false);

      return false;
    }

    setAllFilled(true);

    return true;
  };

  const submitFn = async () => {
    setBtnClicked(true);

    if (!allFilled) {
      if (!formFields.email) {
        setEmailNotExists(true);
      }

      return;
    }

    setScrollValidation(!scrollValidation); //to scroll page where user has not enter data
    setBtnClicked(true);

    const pass = checkAll(); // to check if all the fields are filled

    if (pass) {
      setBtnLoading(true); //enable loading of button
      // let countryCode = formatPhoneNumberIntl(formFields.phone).split(" ")[0];
      let phone = formFields.phone;

      try {
        let leadData = {
          email: formFields.email,
          from_home_page_banner: fromHomepage ? true : false,
          phone: phone,
          name: formFields.name,
          whatsapp_consent: formFields.opt_in === "Yes" ? true : false,
          gclid:
            (gclid?.value ?? null) ||
            (wbraid?.value ?? null) ||
            (gbraid?.value ?? null),
          gclsrc: gclsrc,
          additional_details: {
            gaId: gaID,
            utmMedium: utmMedium,
            utmCampaign: utmCampaign,
            utmSource: utmSource,
            utmContent: utmContent,
            utmTerm: utmTerm,
            gclid_type:
              (gclid?.value ? "gclid" : null) ||
              (wbraid?.value ? "wbraid" : null) ||
              (gbraid?.value ? "gbraid" : null),
          },
          event_details: eventDetails,
        };

        const url = `${CONVEX_API_URL}/program/null/campaign/null/events/${eventDetails?.id}/lead`;

        const res = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(leadData),
        });

        const resData = await res.json();

        if (resData.message == "Invalid Email ID.") {
          setEmailNotExists(true);
          setBtnLoading(false);

          return null;
        }

        if (resData.message == "Lead already exists.") {
          setEmailAlreadyExists(true);
          setBtnLoading(false);

          return null;
        }

        if (!res.ok || resData.status === 400) {
          throw resData?.message ?? "Something went wrong!";
        }

        setBtnLoading(false);
        // return null;
        setShowForm(false);
        setShowThankYou(true);
        setEmailAlreadyExists(false);
        setThankyou(true);

        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            console.log("fbq ran");
            fbq("trackCustom", "FormSubmitKSLive");
          }
        }
      } catch (err) {
        setBtnLoading(false);
        console.log("err", err);
        setErrorOccured(true);
      }
    }
  };


  const thankyouFn = () => {
    setThankyou(false);
    setBtnClicked(false);
    setFormFields({
      name: "",
      email: "",
      phone: "",
      opt_in: "",
    });
    setErrorOccured(false);
    setAllFilled(false);
  };

  const syncGclId = () => {
    let gclidParam = queryParams.get("gclid");
    let wbraidParam = queryParams.get("wbraid");
    let gbraidParam = queryParams.get("gbraid");
    let gclidRecord = null;
    let wbraidRecord = null;
    let gbraidRecord = null;
    let currGclidFormField = document.getElementById("gclid");
    let currWbraidFormField = document.getElementById("wbraid");
    let currGbraidFormField = document.getElementById("gbraid");
    let gclsrcParam = queryParams.get("gclsrc");

    let isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1;

    if (gclidParam || wbraidParam || (gbraidParam && isGclsrcValid)) {
      gclidRecord = getExpiryRecord(gclidParam);
      wbraidRecord = getExpiryRecord(wbraidParam);
      gbraidRecord = getExpiryRecord(gbraidParam);
      localStorage.setItem("gclid", JSON.stringify(gclidRecord));
      localStorage.setItem("wbraid", JSON.stringify(wbraidRecord));
      localStorage.setItem("gbraid", JSON.stringify(gbraidRecord));
    }

    let gclid = gclidRecord || JSON.parse(localStorage.getItem("gclid"));
    let wbraid = wbraidRecord || JSON.parse(localStorage.getItem("wbraid"));
    let gbraid = gbraidRecord || JSON.parse(localStorage.getItem("gbraid"));
    let isGclidValid =
      (gclid && new Date().getTime() < gclid.expiryDate) ||
      (wbraid && new Date().getTime() < wbraid.expiryDate) ||
      (gbraid && new Date().getTime() < gbraid.expiryDate);

    if (
      currGclidFormField ||
      currWbraidFormField ||
      (currGbraidFormField && isGclidValid)
    ) {
      setGclid(gclid);
      setGclidSrc(gclsrc);
      setWbraid(wbraid);
      setGbraid(gbraid);
    }
  };

  return (
    <>
      <div className="ks-live-session-image-container">
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ysuque.png" alt="live-image" />
      </div>
      {!thankyou ? 
    
        <div className="form-container-main">
          {/* <div className="form-input-div"> */}
          <Input
            type="text"
            name="name"
            placeholder="Name*"
            //isRequired={true}
            btnClicked={btnClicked}
            getValuesFn={getValuesFn}
            value={formFields.name}
          />
          {btnClicked && !formFields.name && (
            <div className="bb-error-msg">
            Please enter name here
              {/* <a href="mailto:hi@kraftshala.com">hi@kraftshala.com</a> */}
            </div>
          )}

          <Input
            type="email"
            name="email"
            placeholder="Email*"
            //isRequired={true}
            //btnClicked={btnClicked}
            getValuesFn={getValuesFn}
            value={formFields.email}
          />
          {btnClicked && emailNotExists && (
            <div className="bb-error-msg">
            Please enter valid email ID here
              {/* <a href="mailto:hi@kraftshala.com">hi@kraftshala.com</a> */}
            </div>
          )}

          {/* <div className="form-input"> */}
          {/* <PhoneInputInternational
            type="tel"
            name="phone"
            placeholder="Phone Number*"
            //isRequired={true}
            btnClicked={btnClicked}
            getValuesFn={getValuesFn}
            value={formFields.phone}
          /> */}
          <Input
            type="tel"
            name="phone"
            placeholder="Phone Number*"
            //isRequired={true}
            //btnClicked={btnClicked}
            getValuesFn={getValuesFn}
            value={formFields.phone}
          />
          {/* </div> */}

          {errorOccured && (
            <div className="alreadyExists mtop-1">
            !Oops, something went wrong. Please try again later
            </div>
          )}
          {/* </div> */}
          {emailAlreadyExists && (
            <div className="alreadyExists mtop-1">
            You have already registered. You can check your email for more
            details! In case of any confusion, write to us at
              <a href="mailto:hi@kraftshala.com">hi@kraftshala.com</a>
            </div>
          )}
          {/* <div className="slot-group mtop-1" /> */}
          <div className="whatsapp-radio-container">
            <div className="optin-container">
              {/* Send me the admission process & test details on Whatsapp */}
              <div className="wrapper-check">
                <p className="session-text">Get session details on &nbsp; </p>
                <StaticImage
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m236s74v.webp"
                  alt="whatsapp icon"
                  className="whatsapp"
                  placeholder="none"
                />
              </div>
            </div>
          </div>

          <Whatsapp
            name="opt_in"
            getValuesFn={getValuesFn}
            btnClicked={btnClicked}
            options={["Yes", "No"]}
            checkedValue={formFields.opt_in}
          />

          <div className="mlp-submit">
            {btnLoading ? (
              <div
                className={`submit-btn ${allFilled ? "yellow-bg" : "gray-bg"}`}
              >
              Loading...
              </div>
            ) : (
              <button
                className="ks-live-submit-btn yellow-bg"
                aria-hidden="true"
                type="submit"
                onClick={submitFn}
              >
              Reserve my seat
              </button>
            )}
          </div>
        </div>
        :   <div className="ks-live-thank-you-container"> 
          <div className="text">Your Session Registration<br/> Is Confirmed!</div>
          <div className="thanku">Thank you</div>

          <button
            className="ks-live-submit-btn yellow-bg"
            aria-hidden="true"
            type="submit"
            onClick={thankyouFn}
          >
              Okay
          </button>
        </div>   }
    </>
  );
};

export default NewKsLiveForm;
