// import { StaticImage } from 'gatsby-plugin-image';
import React from "react";
import "./styles.scss";
import Notes from "./NoteFomFounders";

const outcomes = () => {
 
  return (
    <div className="marketing-outcomes">
      <div class="index-bg">03</div>
      <div className="outcomes-sub">Outcomes</div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" className="outcome-undeline" />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul8hdr.png" loading='lazy' className='pc-outcome-dots-right' />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul1dzp.png" loading='lazy' className='pc-outcome-dots-left' />

      <div className="our-promise">Our promise:</div>
      <div className="outcome-desc">
        {" "}
        India’s only PGP with accountability of placements.What does this mean -
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul3okh.png" loading="lazy" className="graph-image" />
      <div className="chanceOfHigh">
        The better you do, the better your chances are of a higher CTC.
        <br />
        But guess what? It's not all up to you.
        <span className="yellow-outcome">
          {" "}
          If your job &lt; 7.5L, we refund 60% fee
        </span>
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y9sinm4v1vliu.png" loading="lazy" className="diary-image mobile-hide" />
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y9sinm4v1oczs.png" loading="lazy" className="diary-image pc-hide" />
      <Notes />
    </div>
  );
};

export default outcomes;
