import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import BlogCard from "../../components/BlogCard/blogCardNew";
import { useState } from "react";

const Header = ({ data, category, title }) => {
  let filteredData = [];

  for(var i=0; i<data.allWpPost.nodes.length ;i++ ){
    let wpData= data.allWpPost.nodes[i];

    if(wpData.acf.category == category && wpData.title != title){
      filteredData.push(wpData);
    }

  }
  



  const size = filteredData.length;
  let [start, setStart] = useState(0);
  let [end, setEnd] = useState(size > 3 ? 3 : size);

  const moveCarousalRight = () => {
    if (end === size) {
      return;
    }

    setStart(() => start + 1);
    setEnd(() => end + 1);
  };
  const moveCarousalLeft = () => {
    if (start === 0) {
      return;
    }

    setStart(() => start - 1);
    setEnd(() => end - 1);
  };

  return (
    <div className="footer" id="blog-footer">
      {filteredData.length > 0 && (
        <>
          <div className="blog-footer-header">Related Articles</div>
          <button
            onClick={moveCarousalLeft}
            disabled={start === 0 ? true : false}
          >
            <img className="arrow" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ypkdyl.png"
            />
          </button>
          <button
            onClick={moveCarousalRight}
            disabled={end === size ? true : false}
          >
            <img className="arrow right" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ypkdyl.png"
            />{" "}
          </button>
          <div className="page-content inner-container">
            {filteredData.slice(start, end).map((blog) => {
              return (
                <BlogCard
                  key={blog.id}
                  thumbnail={blog.featuredImage?.node?.mediaItemUrl}
                  title={blog.title}
                  author={blog.author.node.name}
                  // about={blog.frontmatter.about}
                  link={blog.slug}
                  publishDate={blog.date?.split(",")[0]}
                  readTime={blog.readingTime}
                  category={blog.acf.category}
                />
              );
            })}
          </div>
          {size >3 && <button
            onClick={() => (end === 3 ? setEnd(size) : setEnd(3))}
            className="more">
            Show {end === 3 ? "More" : "Less"}
          </button> }
        </>
      )}
    </div>
  );
};

export default function FooterData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPost {
            nodes {
                id
                date(formatString: "MMM DD,YYYY ")
                title
                slug
                link
                author {
                  node {
                    name
                  }
                }
                readingTime
                categories {
                  nodes {
                    name
                  }
                }
                featuredImage {
                  node {
                    mediaItemUrl
                    
                  }
                }
                acf {
                  showInCarousal
                  category
                }
              }
          }
          
        }
      `}
      render={(data) => <Header data={data} {...props} />}
    />
  );
}

Header.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
