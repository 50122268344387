import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Header from "../../components/Layout/BlogLayout/Header";
import BlogLayout from "../../components/Layout/BlogLayout/SideBlog2";
import Footer from "../../components/Layout/BlogLayout/Footer";
import Seo from "../../components/Seo/seo";
import FooterData from "../blog/FooterData";
import Tracks from "../../components/Layout/BlogLayout/Tracks/CourseTracks";
import "../blog/styling-text";
import PageScrollProgressBar from "react-page-scroll-progress-bar";
import "../../components/Layout/BlogLayout/appBlog.scss";
import { TableOfContents } from "../../components/Layout/BlogLayout/TableOfContents";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card from "../../components/Layout/BlogLayout/Card";
import UpcommingEvent from "../../components/KFLiveSeries/RegistrationSection/Card";
import { Share } from "../../components/Layout/BlogLayout/Share";
import LeadActivityTracker from "../../components/LeadActivityTracker";

const BlogPostPage = ({ data, location }) => {
  const post = data.allWpPost.edges[0].node;
  const author = post.author.node.name;
  const category = post.acf.category;
  const publishDate = post.date;
  const readTime = post.readingTime;
  const title = post.title;
  const toc = post.toc.items;
  const link = location.href;
  const description = post.acf.description;
  const utmLink = post.acf.utmlink;
  const liveMediaImg = post.acf.sidebarBanner?.mediaItemUrl;
  const ksLiveUtm = post.acf.ksLiveUtm;
  const [currentIndex, setCurrentIndex] = useState(0);
  const stickyCtaBarUtmLink = post.acf.stickyCtaBarUtmLink;
  const FtCta = post.acf.mlpFtCardCarousel;
  const ETCta = post.acf.mlpEtCardCarousel;
  const PTCta = post.acf.mlpPtCardCarousel;
  const CSMCta = post.acf.cslpCardCarousel;
  const PGPCta = post.acf.pgpSalesCarousel;

  useEffect(() => {
    const images = document.querySelectorAll('.carouselImg');
    const carousel = document.getElementById('carousel');
    const nextBtn = document.getElementById('nextBtn');
    const prevBtn = document.getElementById('prevBtn');

    const handleNextClick = () => {

      let tempVal = window.innerWidth < 600 ? 1 : 2;

      if (currentIndex < images.length - tempVal) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
        carousel.style.transform = `translateX(-${(currentIndex + 1) * 300}px)`;
      }
    };

    const handlePrevClick = () => {
      if (currentIndex > 0) {
        setCurrentIndex((prevIndex) => prevIndex - 1);
        carousel.style.transform = `translateX(-${(currentIndex - 1) * 300}px)`;
      }
    };

    if (nextBtn && prevBtn) {
      nextBtn.addEventListener('click', handleNextClick);
      prevBtn.addEventListener('click', handlePrevClick);

      // Clean up event listeners on unmount
      return () => {
        nextBtn.removeEventListener('click', handleNextClick);
        prevBtn.removeEventListener('click', handlePrevClick);
      };
    }
  }, [currentIndex]);

  function truncateText(text, maxWords) {
    // Split the input text into words
    const words = text.split(" ");

    // Check if the text has more words than the maximum allowed
    if (words.length > maxWords) {
      // Slice the array to get the first maxWords words, then join them with spaces
      return words.slice(0, maxWords).join(" ") + "...";
    }

    // If the text has fewer or equal words than the maximum allowed, return it as is
    return text;
  }


  return (
    <React.Fragment>
      <Seo
        title={title}
        description={description ?? ""}
        url={"https://www.kraftshala.com" + "/blog/" + post.slug + "/"}
      />
      <div className="navigation">
        <Header />
      </div>
      <PageScrollProgressBar
        color="#F1AE13"
        height="6px"
        class="scrollProgressBar"
        top="89px"
      />
      <div className="bottomBar">
        <Link to={stickyCtaBarUtmLink} className="blogs-fixed-btn">Start My Marketing Career Today</Link>
      </div>

      <div className="things-container">
        <BlogLayout
          toc={toc}
          liveMediaImg={liveMediaImg}
          ksLiveUtm={ksLiveUtm}
          link={utmLink}
        >
          <div className="blog-wrapper-new">
            <div className="navigation-bar blog-nav">
              <Link to="/blog/">Blog</Link> <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ypfhbp.png" />{" "}
              <span>{truncateText(title, 4)}</span>
            </div>
            <h1 id="main-blog-page-header">{title}</h1>

            {author && category && publishDate && readTime && (
              <div className="author-category-blog">
                {author} in{" "}
                <span>
                  <Link to="/blog/">
                    <u> {category}</u>{" "}
                  </Link>{" "}
                </span>{" "}
                <span className="icon-text" style={{ margin: "0 10px" }}>
                  <CalendarMonthIcon fontSize="small" sx={{ transform: "translateY(4px)" }} />{" "}
                  {publishDate?.split(",")[0]}
                </span>{" "}
                {"  "}
                <span className="icon-text">
                  <AccessTimeFilledIcon
                    fontSize="small"
                    sx={{ transform: "translateY(5px)", marginRight: "2px" }}
                  />
                  {readTime} min
                </span>
              </div>
            )}
            <div className="pc-none">
              <hr className="mt-1" />
              <TableOfContents toc={toc} />
            </div>
            <div
              id={post.id}
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
          {/* sidebars related courses */}
          <div className="shareArea pc-none">
            <hr />
            <Share link={link} />
            <hr className="mb-1" />
          </div>

          <div className="sidebar pc-none">
            <div className="header">Related courses</div>

            <Card
              title="Marketing Launchpad"
              about="This is 19 week online full-time program for which we select, train and invest in high potential graduates to help them start a fantastic career in marketing. The best part? You pay ONLY when you land a job with a minimum CTC of INR 4.5 lakhs."
              trademark="1800+ students have secured a marketing job without an MBA."
              link={utmLink}
              list={[
                "19 weeks, online, full-time program",
                "Get hired in performance marketing, content, social media, and more",
                "Highest CTC: ₹9.2 LPA",
                "World’s largest agencies hire from us again and again",
                "94% placement rate",
              ]}
            />
            {/* <Sidebar3 /> */}
            <div className="sideBarUpcommingEvent">
              <UpcommingEvent liveMediaImg={liveMediaImg} ksLiveUtm={ksLiveUtm}/>
            </div>
          </div>
          <Tracks FtCta={FtCta} ETCta={ETCta} PTCta={PTCta} slug={post.slug} CSMCta={CSMCta} PGPCta={PGPCta} />
          <FooterData category={category} title={title} />
        </BlogLayout>
      </div>
      <Footer />
      <LeadActivityTracker />
    </React.Fragment>
  );
};

export const query = graphql`
  query($slug: String) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          slug
          toc
          date(formatString: "MMM DD, YYYY ")
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
          readingTime
          id
          acf {
             cslpCardCarousel
              pgpSalesCarousel
              mlpPtCardCarousel
              mlpFtCardCarousel
              mlpEtCardCarousel
              ksLiveUtm
              description
              utmlink
              showInCarousal
              category
              stickyCtaBarUtmLink
              sidebarBanner {
                mediaItemUrl
              }
            }
        }
      }
    }
  }
`;

export default BlogPostPage;
