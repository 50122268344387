import React from "react";

import "./styles.scss";
import Card from "./card";
import Slider from "react-slick";

const Programs = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      <main className="kraftshala-programs-main">
        <div className="programs-heading-main">
          <h1 className="main-header">
                        India’s Largest Job Providing Programs
          </h1>
          <h3 className="sub-header">
                        Select the program that perfectly matches your career ambitions:
          </h3>
        </div>
        <div className="program-cards-main">
          <Slider {...settings}>
            {
              cardData.map(data => {
                return (
                  <React.Fragment key={data.heading}>
                    <Card cardData={data} location={props.location}/>
                  </React.Fragment>
                );
              })
            }
          </Slider>
        </div>
        <div className="progarms-footer-main-desktop">
          <span className="highlight-yellow">1800+</span> Students Placed . <span className="highlight-yellow">94%</span> Placement Rate
        </div>
        <div className="progarms-footer-main-mob">
          <span className="highlight-yellow">1800+</span> Students Placed <br/>
          <span className="highlight-yellow">94%</span> Placement Rate
        </div>
      </main>
    </>
  );
};

const cardData = [
  {
    heading: 
            (<>
                Marketing Launchpad <span className="muted-text">Full Time</span>
            </>),
    description:
            (
              <>
                <b>A Digital Marketing Program</b> where you graduate with a job paying <span className="highlight-yellow">₹4.5 - 9 LPA</span>
              </>
            ),
    duration: <>19 Weeks. Full Time</>,
    applyURL: `/marketing-launchpad/digital-marketing-course/`
  },
  {
    heading: 
            (<>
                Content & Social Media Launchpad <span className="muted-text">Full Time</span>
            </>),
    description:
            (
              <>
                <b> A Content Writing and Social Media Launchpad</b> where you graduate with a job paying <span className="highlight-yellow">₹4.5 - 9 LPA.</span>
              </>
            ),
    duration: <>16 Weeks. Part Time</>,
    applyURL: `/content-writing-course/`
  },
  {
    heading: 
            (<>
                 PGP in Sales and Business Leadership   <span className="muted-text">with Specialization in Sales</span>
            </>),
    description:
            (
              <>
                <b>A Training Program</b> where you kickstart your business & sales career and unlock high-paying jobs with salaries of <span className="highlight-yellow">₹9-15 LPA</span>
              </>
            ),
    duration: <>9 Months. Full Time</>,
    applyURL: `/sales-course/`,
    isNew: true
  },
];

export default Programs;