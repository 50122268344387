import React from "react";
import "./styles.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import Pledge from "./Pledge";

function Fee() {
  const matches = useMediaQuery(`(max-width: 900px)`);

  return (
    <div className="basl-main-fee">
      <h2 className="fee-header">
        Fees of PGP in Sales & <br className="pc-hide" />
        Business Leadership
        <br />
      </h2>
      <div className="basl-fee-new-underline">
        <img
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
          loading="lazy"
          className="basl-fee-new-underline"
        />
      </div>

      <table className="basl-fee-table mobile-hide">
        <thead>
          <tr>
            <th>
              Admission
              <br />
              Rounds
            </th>
            <th>
              Application
              <br /> Deadline
            </th>
            <th>Program Fee</th>
            <th>Apply Now</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Early Admission 1</td>
            <td>July</td>
            <td>₹ 170,000</td>
            <td>
              <button disabled onClick={""} class="btn-yet-to-open">
                Closed
              </button>
            </td>
          </tr>
          <tr className="yellow-bg">
            <td>Early Admission 2</td>
            <td>January</td>
            <td>₹ 185,000</td>
            <td>
              <a href="#hero-div" class="btn-apply-now">
                Apply Now
              </a>
            </td>
          </tr>
          <tr>
            <td>Final Admission</td>
            <td>March</td>
            <td>₹ 200,000</td>
            <td>
              <button disabled onClick={""} class="btn-yet-to-open">
                Yet to Open
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <b>
                EMIs starting ~₹10,000/month are available with our non-banking
                financing companies
              </b>
              <br />
              Fee amounts mentioned above are exclusive of 18% GST
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="pgp-fee-mob">
        <div className="pgp-fee-card">
          <div className="pgp-fee-row">
            <div className="pgp-card-head">Early Admission 1</div>
            {/* <div className="pgp-card-day-left">15 days left</div> */}
          </div>
          <div className="pgp-fee-row">
            <div className="pgp-card-deadline">Deadline: July</div>
            <div className="pgp-card-deadline">Fee: ₹ 170,000</div>
          </div>
          <button className="pgp-btn ">Closed</button>
        </div>
        <div className="pgp-fee-card yellow-bg">
          <div className="pgp-fee-row">
            <div className="pgp-card-head">Early Admission 2</div>
          </div>
          <div className="pgp-fee-row">
            <div className="pgp-card-deadline">Deadline: January </div>
            <div className="pgp-card-deadline">Fee: ₹ 185,000</div>
          </div>
          <button
            onClick={() => {
              document.querySelector("#hero-div").scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }}
            className="pgp-btn yellow-bg-btn"
          >
            Apply Now
          </button>
        </div>
        <div className="pgp-fee-card">
          <div className="pgp-fee-row">
            <div className="pgp-card-head">Final Admission</div>
          </div>
          <div className="pgp-fee-row">
            <div className="pgp-card-deadline">Deadline: March</div>
            <div className="pgp-card-deadline">Fee: ₹ 200,000</div>
          </div>
          <button className="pgp-btn ">Yet to open</button>
        </div>
      </div>
      <div className="pgp-fee-outro">
        <ol>
          <li>EMIs starting ~₹10,000/month are available  with our NBFC  partners
          </li><li>Fee amounts mentioned above are exclusive of 18% GST</li>
        </ol>
      </div>

      <Pledge />
    </div>
  );
}

export default Fee;
