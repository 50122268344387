import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import './styles.scss';

const index = () => {
  return (
    <div className="wwu-social">
      <div className="wwu-social-head">Let’s get Social</div>
      <StaticImage
        className="wwu-social-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-icons-tray">
        <div className="insta wwu-icon" onClick={() => window.open('https://www.instagram.com/kraftshala/',"_blank")}></div>
        <div className="x wwu-icon" onClick={() => window.open('https://x.com/kraftshala?lang=en',"_blank")}></div>
        <div className="in wwu-icon" onClick={() => window.open('https://www.linkedin.com/school/learn-kraftshala/',"_blank")}></div>
        <div className="youtube wwu-icon" onClick={() => window.open('https://www.youtube.com/@Kraftshala',"_blank")}></div>
      </div>
      
    </div>
  );
};

export default index;
