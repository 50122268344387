import { useLocation } from "@reach/router";
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { CONVEX_API_URL } from "../../../config/config";

const LeadActivityTracker = (props) => {
  const leadID = props?.params?.lead_id ?? null;
  const location = useLocation();

  useEffect(() => {
    if(!location)
      return;
    trackActivity();
  }, [location]);

  const trackActivity = async () => {
    try {
      let activeUserUUID = localStorage.getItem("activeUserUUID");

      if(!activeUserUUID)
        activeUserUUID = generateNewUUID();
      let params = new URLSearchParams(location.search);
      let utm_medium = params.get("utm_medium") || "No Utm Medium";
      const utm = getUTMDetails(location.search);
      const deviceDetails = getDeviceDetails();
      const trackingData = {
        lead_id: activeUserUUID,
        path_url: location.href,
        utm_medium,
        utm_details: utm,
        device_details: deviceDetails, 
        ...(leadID && {actual_lead_id: leadID})
      };

      const postLeadData = await fetch(`${CONVEX_API_URL}/lead_tracking`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(trackingData),
      });
      const resData = await postLeadData.json();
    } catch (error) {
      console.error(error);
    }
  };

  const generateNewUUID = () => {
    const newUUID = uuidv4();

    localStorage.setItem("activeUserUUID", newUUID);

    return newUUID;
  };

  const getUTMDetails = (search) => {
    let params = new URLSearchParams(search);
    let utm_source = params.get("utm_source") || "No Utm Source";
    let utm_content = params.get("utm_content") || "No Utm Content";
    let utm_campaign = params.get("utm_campaign") || "No Utm Campaign";
    let utm_term = params.get("utm_term") || "No Utm Term";
    let utm_placement = params.get("utm_placement") || "No Utm Placement";

    const utmObj = {
    //   utm_medium,
      utm_source,
      utm_content,
      utm_campaign,
      utm_term,
      utm_placement
    };

    return JSON.stringify(utmObj);
  };

  const getDeviceDetails = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
      
    // Determine device type
    let deviceType = "Desktop";

    if (/Mobi|Android/i.test(userAgent)) {
      deviceType = "Mobile";
    } else if (/Tablet|iPad/i.test(userAgent)) {
      deviceType = "Tablet";
    }
      
    // Determine browser
    let browser = "Unknown Browser";

    if (userAgent.includes("Firefox")) {
      browser = "Firefox";
    } else if (userAgent.includes("Edg")) {
      browser = "Edge";
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
      browser = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      browser = "Safari";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      browser = "Opera";
    } else if (userAgent.includes("Trident") || userAgent.includes("MSIE")) {
      browser = "Internet Explorer";
    }
      
    // Determine operating system
    let operatingSystem = "Unknown OS";

    if (/Win/i.test(platform)) {
      operatingSystem = "Windows";
    } else if (/Mac/i.test(platform)) {
      operatingSystem = "MacOS";
    } else if (/Linux/i.test(platform)) {
      operatingSystem = "Linux";
    } else if (/Android/i.test(userAgent)) {
      operatingSystem = "Android";
    } else if (/iOS|iPhone|iPad|iPod/i.test(userAgent)) {
      operatingSystem = "iOS";
    }
      
    return `${deviceType} | ${browser} | ${operatingSystem}`;
  };
      
  return "";
};

export default LeadActivityTracker;